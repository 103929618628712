module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Carmen Automotive","short_name":"CARMEN","start_url":"/?source=pwa","background_color":"#ffffff","theme_color":"#F6A03E","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"103486d6be7066d20e690c613483ad22"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://19139704c4324b49b975b7c67aeb9d1a@o984794.ingest.sentry.io/5940703","tracesSampleRate":1,"release":"gatsby@2024-09-18T09:03:49.980Z","environment":"production","enabled":true,"debug":false},
    },{
      plugin: require('../../../packages/gatsby-theme-apolloclient/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-menus/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/pdv/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"verbose":false,"url":"https://shop.dcdw.nl/graphql","debug":{"graphql":{"writeQueriesToDisk":false,"showQueryVarsOnError":false,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"timeout":60000,"perPage":50,"requestConcurrency":10,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"html":{"useGatsbyImage":false,"generateWebpImages":false,"generateAvifImages":false,"imageMaxWidth":992,"fallbackImageMaxWidth":992,"imageQuality":90,"placeholderType":"blurred","createStaticFiles":true},"type":{"Post":{"limit":null},"EnglishPost":{"limit":1},"DeutschPost":{"limit":1},"FrancaisPost":{"limit":1},"Podcast":{"limit":null,"exclude":true},"MediaItem":{"createFileNodes":false,"localFile":{"excludeByMimeTypes":["video/mp4","video/quicktime","application/pdf"],"maxFileSizeBytes":15728640,"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"Taxonomy":{"exclude":true},"ContentType":{"exclude":true},"Comment":{"exclude":true},"Tag":{"exclude":true},"UserRole":{"exclude":true},"Page":{"exclude":true},"Coupon":{"exclude":true},"Customer":{"exclude":true},"Order":{"exclude":true},"ShippingMethod":{"exclude":true},"ShippingClass":{"exclude":true},"Refund":{"exclude":true},"TaxRate":{"exclude":true},"ProductTag":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-41FTRR0KD8"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
